.admin_container {
    flex: 4;
}

.admin_sidebar {
    display: flex;
}

.admin_content {
    flex: 4;
    margin: 10px 10px 40px 10px ;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-x: scroll;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.subtitle {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  text-align: center;
}

.add_form {
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
  width: 50%;
  margin: 0 auto;
}

.add_form input {
  outline: 0;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.admin_title {
  margin-top: 20px;
}
  
  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th,
  td {
    border: 1px solid #ffffff;
    text-align: left;
    padding: 8px;
    font-size: 16px;
  }
  
  th {
    background-color: rgb(253, 130, 92);
    color: #ffffff;
  }
  
  td {
    background-color: rgba(253, 206, 191, 0.233);
  }

  .table_title {
      font-size: 22px;
      font-weight: 800;
  }

  .employee_table {
      margin-top: 50px;
  }

  .employee_wrapper {
    overflow-x: auto;
  }
  
  form {
    display: flex;
    gap: 5px;
  }
  
  form td:last-child {
    display: flex;
    justify-content: space-evenly;
  }
  
  form * {
    font-size: 18px;
  }

.employee_add_form {
    display: grid;
    place-items: center;
    border: 1px solid red;
    width: 70%;
    margin: 0 auto;
    row-gap: 15px;
    background-color: gray;
    padding: 20px 0;
}

.employee_add_form input {
    width: 350px;
    padding: 5px 10px;
    border: 1px solid gray;
    outline: 0;
}

.email,
.position {
    margin-left: 10px;
}

.add_btn {
    color: #ffffff;
    padding: 8px 25px;
    background-color: rgb(253, 130, 92);
    border-radius: 10px;
    font-weight: 600;
}

.add_btn:hover,
.edit_btn:hover,
.delete_btn:hover,
.cancel_btn:hover,
.update_btn:hover {
  opacity: 0.8;
}

.edit_btn {
    padding: 3px 15px;
    background-color: rgb(95, 190, 95);
    border-radius: 10px;
    font-weight: 600;
    color: #ffffff;
    margin-right: 10px;
}

.update_btn {
    padding: 3px 15px;
    background-color: rgb(153, 132, 250);
    border-radius: 10px;
    font-weight: 600;
    margin-right: 5px;
    color: white;
}

.delete_btn {
  padding: 3px 15px;
  background-color: rgb(214, 79, 79);
  border-radius: 10px;
  font-weight: 600;
  color: #ffffff;
}

.employee_title {
    text-align: center;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 600;
}

.cancel_btn {
  padding: 3px 15px;
  background-color: rgb(155, 155, 155);
  border-radius: 10px;
  font-weight: 600;
  color: #ffffff;
}