.qrcode_container {
    flex: 4;
}

.qrcode_wrapper {
    display: flex;
}

.qrcode_content {
    flex: 4;
    margin: 10px;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}