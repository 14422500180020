* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
}

.navbar {
    width: 100%;
    height: 50px;
    background-color: white;
    border-bottom: 1px solid black;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}

.navbar_left {
    cursor: pointer;
}

.navbar_right {
    display: flex;
    align-items: center;
    gap: 15px;
}

.logo {
    font-weight: 900;
    font-size: 20px;
}

.navbar_icons_container {
    position: relative;
    cursor: pointer;
    color: grey;
}

.navbar_icon_badge {
    position: absolute;
    top: -5px;
    right: 0px;
    color: white;
    background-color: orangered;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.user_icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}