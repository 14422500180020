.sidebar_container {
  flex: 1;
  height: calc(100vh - 50px);
  position: sticky;
  top: 50px;
  left: 20px;
}

.sidebar_wrapper {
  margin: 8px 0 5px 0;
  padding: 15px 40px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.sidebar_menu {
  margin-bottom: 10px;
}

.sidebar_title {
  font-size: 30px;
}

.sidebar_title:hover{
  color: rgb(0, 0, 68);
}

.sidebar_list {
  list-style: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar_list_item:hover {
  background-color: rgb(253, 130, 92);
  color: white;
  opacity: 0.85;
}

.sidebar_list_item {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.sidebar_list_item.active,
.sidebar_list_item:hover {
  background-color: rgb(253, 130, 92);
  color: white;
}

.sidebar_icon {
  font-size: 20px !important;
}
