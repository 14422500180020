.chart_container {
    margin: 20px;
    padding: 20px;
    box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.5);
    -webkit-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.5);
    border-radius: 10px;
}

.chart_title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
}