@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

* {
  font-family: 'Lato', sans-serif;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

a:hover {
  color: white;
}

span.carousel-control-next-icon {
  background-color: #e77449;
  border-radius: 5px;
  display: none;
}

span.carousel-control-prev-icon {
  background-color: #e77449;
  border-radius: 5px;
  display: none;
}

div.carousel-indicators {
    background-color: #e77449;
    opacity: 0.8;
    display: none;
}

.home_container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('./assets/welcome.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.homepage_container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('./assets/photo1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.logout_btn:hover {
  opacity: 0.8;
}

.logout_container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url('./assets/photo2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.btn-primary, .btn-success {
  margin-left: 15px;
  margin-top: 15px;
}

.qrcode_btn:hover {
  opacity: 0.8;
}

.confirmation-code-container{
  display: flex;
  gap: 1rem;
}

.confirmation-code-container input{
  width: 40px;
  height: 40px;
  font-size: 1.2em;
  text-align: center;
}
.textarea_container {
  padding: 10px;
  border: 2px solid black;
}
iframe#webpack-dev-server-client-overlay{display:none!important}
