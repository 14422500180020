.host_container {
    flex: 4;
}

.host_wrapper {
    display: flex;
}

.host_content {
    flex: 4;
    margin: 10px 10px 40px 10px ;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.host_content_title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
}

.host_action_container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 5px 0;
}

.host_edit {
    border: none;
    border-radius: 7px;
    background-color: #3bb077;
    color: white;
    height: 30px;
    font-size: 16px;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.host_edit:hover,
.host_delete:hover {
    opacity: 0.6;
}

.host_delete {
    color: red;
    cursor: pointer;
}

.host_delete:hover {
    transform: scale(1.5);
}